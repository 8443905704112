export const formatAmountBasedOnCurrency = (
  currency: string | undefined,
  amount: string | number
): string => {
  if (!currency) return '';
  const stablecoinAndFiatDecimalPlaces = 2;
  const cryptoDecimalPlaces = 4;
  const decimalPlaces = isStablecoin(currency) || checkCurrencyType(currency) === 'fiat' ? stablecoinAndFiatDecimalPlaces : cryptoDecimalPlaces;
  const formattedValue = formatValue(Number(amount).toFixed(decimalPlaces));
  return formattedValue;
};
