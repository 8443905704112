export const STABLECOINS = [
  'USDT',
  'USDC',
  'BUSD',
  'DAI',
  'USDP',
  'TUSD',
  'GUSD',
  'UST',
  'USDN',
  'sUSD'
];
